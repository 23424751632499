import React, { Component } from 'react'
import './App.scss';
// import About from './components/About/About'
// import Contact from './components/Contact/Contact'
// import Works from './components/Works/Works'
// import E404 from './components/E404/E404'
// import Home from './components/Home/Home'
// import Menu from './components/Layout/Menu'
// import { BrowserRouter, Route, Routes } from 'react-router-dom'

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: '',
        }
        this.setPage = this.setPage.bind(this)
    }

    setPage(page, name) {
        this.setState({ page: page })
        document.body.className = page
        document.title = 'uxpro.dev'
    }

    componentDidMount() {
        document.body.className = this.state.page
    }

    render() {
        return (
            <div className='holder'>
                <div className='header'>
                    <div className='wrapper'>
                        <h1 className='logo'><span>uxpro.dev</span></h1>
                    </div>
                </div>
                {/* <BrowserRouter>
                    <div className='header'>
                        <div className='wrapper'>
                            <h1 className='logo'><span>Alex Mihăileanu</span></h1>
                            <Menu location={ this.state.page } setPage={ this.setPage } />
                        </div>
                    </div>
                    <Routes>
                        <Route exact path='/' element={ <Home /> } />
                        <Route path='/about' element={ <About /> } />
                        <Route path='/contact' element={ <Contact /> } />
                        <Route path='/work' element={ <Works /> } />
                        <Route element={ <E404 /> } />
                    </Routes>
                    <div className='footer'>
                        <p>Alex Mihăileanu {new Date().getFullYear()}</p>
                    </div>
                </BrowserRouter> */}
            </div>
        )
    }
}

export default App;
